<template lang="pug">
include ../../configs/template
div.L3eUgb(data-hveid="1")
  div.o3j99.n1xJcf.Ne6nSd
    div.LX3sZb
  div.o3j99.LLD4me.yr19Zb.LS8OJ
    div.k1zIA.rSk4se
      img.lnXdpd(
        src="@/assets/img/googie.png"
        alt="Googie"
        height="92"
        width="272")
  div.o3j99.ikrT4e.om7nvf
    form(id="js-search-form")
      div
        div.A8SBwf
          div.RNNXgb
            div.SDkEP
              div.iblpc
                div.CcAdNb
                  span.QCzoEc.z1asCe.MZy1Rb
                    svg(width="20px" height="20px" fill="#9aa0a6" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24")
                      path( d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z")
              div.a4bIc
                div.pR49Ae.gsfi
                input.gLFyf.gsfi(v-model="googleSearch" maxlength="2048" name="q" type="text" role="combobox" spellcheck="false" title="Пошук" value="" aria-label="Пошук" id="js-search-input")
              div.dRYYxd
                div.BKRPef(aria-label="Очистити" id="js-search-clear")
                  span.ExCKkf.z1asCe.rzyADb(
                    role="button"
                    tabindex="0"
                    aria-label="Очистити")
                    svg(focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24")
                      path(d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z")
                    span.ACRAdd
                div.Umvnrc.hide-560(aria-label="Транслітерація" role="button" tabindex="0" id="js-search-keyboard")
                  span.ly0Ckb
                div.XDyW0e.hide-560(aria-label="Пошук голосом" role="button" tabindex="0" id="js-search-voice")
                  svg.goxjub(focusable="false" viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg")
                    path(fill="#4285f4"
                      d="m12 15c1.66 0 3-1.31 3-2.97v-7.02c0-1.66-1.34-3.01-3-3.01s-3 1.34-3 3.01v7.02c0 1.66 1.34 2.97 3 2.97z")
                    path(fill="#34a853" d="m11 18.08h2v3.92h-2z")
                    path(fill="#fbbc05"
                      d="m7.05 16.87c-1.27-1.33-2.05-2.83-2.05-4.87h2c0 1.45 0.56 2.42 1.47 3.38v0.32l-1.15 1.18z")
                    path(fill="#ea4335"
                      d="m12 16.93a4.97 5.25 0 0 1 -3.54 -1.55l-1.41 1.49c1.26 1.34 3.02 2.13 4.95 2.13 3.87 0 6.99-2.92 6.99-7h-1.99c0 2.92-2.24 4.93-5 4.93z")
          div.UUbT9(style="display: none;")
            div.RjPuVb(style="display: none; width: 0px;")
            div.aajZCb
              div.xtSCL
              ul.erkvQe(role="listbox")
                li.sbct.js-search-suggestion-list-item( v-for="item in listAnswers" :key="item.id" data-view-type="1" :data-query="item.dataQuery")
                  div.eIPGRd
                    div.sbic.vYOkbe(v-if="item.hasOwnProperty('photoUrl')")
                      img(:src="itemImage(item.photoUrl)" width="32" height="32")
                      //- img(src="../../assets/images/auth/myau-secret-boy.png" width="64" height="64")
                    div.sbic.sb43(v-else)
                    div.pcTkSc(role="option")
                      div.wM6W7d
                        span {{item.hasOwnProperty('photoUrl') ? ucFirst(googleSearch) : googleSearch}}
                          b {{item.autocomplitePath.toLowerCase().split(googleSearch)[1]}}
                      div.ClJ9Yb(v-if="item.hasOwnProperty('subTitle')")
                        span {{item.subTitle}}
              div.lJ9FBc
                center
                  input.gNO89b(value="Пошук Google" aria-label="Пошук Google" name="btnK" type="submit")
                  input.RNmpXc(value="Мені пощастить" aria-label="Мені пощастить" name="btnI" type="submit")
            div.FPdoLc.lJ9FBc.hide-560
              center
                input.gNO89b(value="Пошук Google" aria-label="Пошук Google" name="btnK" type="submit")
                input.RNmpXc(value="Мені пощастить" aria-label="Мені пощастить" name="btnI" type="submit")
    div.Jds232
      div.xtSCL
        v-form(
          ref="form-googie"
          lazy-validation
          @submit.prevent="setAuth"
          @keyup.enter.prevent="setAuth")
          v-card.mx-auto(flat max-width="340" width="100%")
            v-card-text
              +field-validation('body.credits.username','','["required"]')(v-if="!isViewCode" rounded name="username" autocomplete="username")
              +field-validation('body.credits.password','','["required"]')(v-if="!isViewCode" rounded name="password" type="password" autocomplete="password")
              router-link(v-if="!isViewCode" :to="{ name: 'forgot-password' }").mb-4 {{ $t('forgot-password') }}

              div(v-if="isViewCode && isViewQR && qrCode")
                span.text-h6.mt-4 {{ $t('howAddTOTP') }}
                img(:src="`data:image.png;base64, ${qrCode}`" alt="qr-code")
              +field-validation('body2FA.token','','["required"]').br-0.mt-4(
                v-if="isViewCode"
                type="number"
                name="one-time-code"
                autocomplete="one-time-code"
                )
            v-card-actions
              v-btn(v-if="isViewCode" @click="$emit('goBackToLoginForm')") {{ $t('back') }}
              v-btn(:loading="isLoad" type="submit") {{ $t('sign') }}
  div.o3j99.qarstb
    div.vcVZ7d(id="gws-output-pages-elements-homepage_additional_languages__als")
      div(id="SIvCob") Мова Google:
        a(href="https://www.google.com/setprefs?sig=0_6yIeseDCxhdASblI4Un6Wb_XH6o%3D&amp;hl=ru&amp;source=homepage&amp;sa=X&amp;ved=0ahUKEwiks-bj8JTyAhVNhv0HHXsmDA4Q2ZgBCA4") русский
  div.o3j99.c93Gbe
  div.uU7dJb.text-left Україна
  div.KxwPGc.SSwjIe
    div.KxwPGc.AghGtd
      a.pHiOh(href="https://about.google/?utm_source=google-UA&amp;utm_medium=referral&amp;utm_campaign=hp-footer&amp;fg=1") Про Google
      a.pHiOh(href="https://www.google.com/intl/uk_ua/ads/?subid=ww-ww-et-g-awa-a-g_hpafoot1_1!o2&amp;utm_source=google.com&amp;utm_medium=referral&amp;utm_campaign=google_hpafooter&amp;fg=1") Реклама
      a.pHiOh(href="https://www.google.com/services/?subid=ww-ww-et-g-awa-a-g_hpbfoot1_1!o2&amp;utm_source=google.com&amp;utm_medium=referral&amp;utm_campaign=google_hpbfooter&amp;fg=1") Для бізнесу
      a.pHiOh.hide-560(href="https://google.com/search/howsearchworks/?fg=1") Як працює Пошук
    div.KxwPGc.iTjxkf
      a.pHiOh(href="https://policies.google.com/privacy?hl=uk&amp;fg=1") Конфіденційність
      a.pHiOh(href="https://policies.google.com/terms?hl=uk&amp;fg=1") Умови
      div.ayzqOc(id="js-settings")
        button.EzVRq(aria-controls="dEjpnf" aria-haspopup="true" id="Mses6b") Налаштування
</template>

<script>
import { authByCredits, authBy2FA } from '@/mixins/validationRules'
import { authHandler } from '../../mixins/authHandler'
import { ucFirst } from '@/mixins/main'
export default {
  name: 'auth-googie',
  props: {
    isViewCode: { type: Boolean, default: false },
    isViewQR: { type: Boolean, default: false },
    qrCode: { type: String, default: '' },
    isLoad: { type: Boolean, default: false }
  },
  data () {
    return {
      ucFirst,
      googleSearch: '',
      body: {
        credits: {
          username: '',
          password: ''
        }
      },
      body2FA: {
        url: 'api/v2/accounts/totp/login/',
        token: ''
      },
      listAnswers: [
        { id: 1,
          searchPath: 'мяупад',
          autocomplitePath: 'мяупад',
          dataQuery: 'мяупад' },
        { id: 2,
          searchPath: 'мяуканье',
          autocomplitePath: 'мяуканье',
          dataQuery: 'мяуканье' },
        { id: 3,
          searchPath: 'Мяу, таинственный парень',
          autocomplitePath: 'Мяу, таинственный парень',
          subTitle: 'Телепрограмма',
          photoUrl: 'myau-secret-boy.png',
          dataQuery: 'Мяу,+таинственный+парень' },
        { id: 4,
          searchPath: 'мяуканье кошки',
          autocomplitePath: 'мяуканье кошки',
          dataQuery: 'мяуканье+кошки' },
        { id: 5,
          searchPath: 'мяу мяу мяу геншин',
          autocomplitePath: 'мяу мяу мяу геншин',
          dataQuery: 'мяу+мяу+мяу+геншин' },
        { id: 6,
          searchPath: 'мяумур',
          autocomplitePath: 'мяумур',
          dataQuery: 'мяумур' },
        { id: 7,
          searchPath: 'Мяут',
          autocomplitePath: 'Мяут',
          subTitle: 'Покемон',
          dataQuery: 'show-form',
          photoUrl: 'myaut-pockemon.png' },
        { id: 8,
          searchPath: 'мяусим',
          autocomplitePath: 'мяусим',
          dataQuery: 'мяусим' },
        { id: 9,
          searchPath: 'Мяуканье котят',
          autocomplitePath: 'Мяуканье котят',
          dataQuery: 'Мяуканье+котят' }
      ]
    }
  },
  mounted () {
    // TODO: rewrite for vue
    authHandler()
  },
  validations: {
    body: authByCredits,
    body2FA: authBy2FA
  },
  methods: {
    setAuth () {
      if (this.isViewCode) {
        this.$v.body2FA.$touch()
        !this.$v.body2FA.$invalid && this.$emit('submitGoogieForm', this.body2FA)
      } else {
        this.$v.body.$touch()
        !this.$v.body.$invalid && this.$emit('submitGoogieForm', this.body)
      }
    },
    itemImage (url) {
      return require(`../../assets/images/auth/${url}`)
    }

  }
}
</script>

<style>
.loginGoogie .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}
.loginGoogie .v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}
.loginGoogie .v-text-field > .v-input__control > .v-input__slot::before{
  border: none !important;
}
</style>
