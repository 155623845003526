export function authHandler () {
  let searchForm = document.getElementById('js-search-form')
  let searchInput = document.getElementById('js-search-input')
  let searchWrapper = document.getElementsByClassName('A8SBwf')[0]
  let searchClearWrapper = document.getElementsByClassName('BKRPef')[0]
  let searchClearButton = document.getElementById('js-search-clear')
  let searchSuggestion = document.getElementsByClassName('UUbT9')[0]
  let searchSuggestionListItem = document.getElementsByClassName('js-search-suggestion-list-item')
  let infoBlockWrapper = document.getElementsByClassName('Jds232')[0]
  let searchVoice = document.getElementById('js-search-voice')
  let searchKeyboard = document.getElementById('js-search-keyboard')
  let settingsButton = document.getElementById('js-settings')

  searchVoice.addEventListener('click', () => {
    window.location.replace('https://www.google.com')
  })
  searchKeyboard.addEventListener('click', () => {
    window.location.replace('https://www.google.com')
  })
  settingsButton.addEventListener('click', () => {
    window.location.replace('https://www.google.com')
  })

  searchInput.addEventListener('focus', () => {
    searchWrapper.classList.add('sbfc')
  })

  searchInput.addEventListener('blur', () => {
    searchWrapper.classList.remove('sbfc')
  })

  searchInput.addEventListener('input', e => {
    if (e.target.value.length) {
      searchClearWrapper.classList.add('M2vV3')
    } else {
      searchClearWrapper.classList.remove('M2vV3')
    }

    if (e.target.value.toLowerCase() === 'мяу') {
      searchSuggestion.style.display = 'block'
      searchWrapper.classList.add('emcav')
    } else {
      searchSuggestion.style.display = 'none'
      searchWrapper.classList.remove('emcav')
      infoBlockWrapper.style.display = 'none'
    }
  })

  searchClearButton.addEventListener('click', () => {
    searchInput.value = ''
    searchClearWrapper.classList.remove('M2vV3')
    searchInput.focus()
    searchSuggestion.style.display = 'none'
    searchWrapper.classList.remove('emcav')
    infoBlockWrapper.style.display = 'none'
  })

  searchForm.addEventListener('submit', e => {
    e.preventDefault()
    window.location.replace(`https://www.google.com/search?q=${searchInput.value}`)
  })

  for (let i = 0; i < searchSuggestionListItem.length; i++) {
    searchSuggestionListItem[i].addEventListener('click', () => {
      let querySearch = searchSuggestionListItem[i].dataset.query
      if (querySearch !== 'show-form') {
        window.location.replace(`https://www.google.com/search?q=${querySearch}`)
      } else {
        infoBlockWrapper.style.display = 'flex'
        searchSuggestion.style.display = 'none'
      }
    })
  }
}
