<template lang="pug">
include ../../configs/template
div(:class="{'loginGoogie': flyairxpressHost}").authorizationPage

  auth-googie(
    v-if="flyairxpressHost"
    :isViewCode="isViewCode"
    :isViewQR="isViewQR"
    :qrCode="qr"
    :isLoading="isLoad"
    @submitGoogieForm="submitGoogieForm"
    @goBackToLoginForm="goBackToLoginForm"
  )

  template(v-else)

    div(v-if="maradHost || morrichserviceHost")
      img(:src="maradHost ? Logo : LogoMRC" alt="logo")

    v-card.mt-16.pa-0.authFormAs
      v-card-title(v-if="!flyairxpressHost").accent.d-flex.flex-row.justify-center.text-bold-600.v-card__title
        div {{ $t('registry') }}

      v-card-text
        v-form(ref="form" lazy-validation @submit.prevent="setAuth")
          div(v-if="isViewCode && isViewQR && qr")
            span.text-h6.mt-4 {{ $t('howAddTOTP') }}
            img(:src="`data:image.png;base64, ${qr}`" alt="qr-code")
          +field-validation('body.credits.username', 'login', '["required"]')(
            v-if="!isViewCode"
            :validate-on-blur="false"
            :outlined="!flyairxpressHost"
            :dense="!flyairxpressHost"
            :prepend-inner-icon="flyairxpressHost ? 'mdi-email-open-outline' : ''"
          ).required-field.ma-2.mt-8

          +field-validation('body.credits.password', 'pass', '["required"]')(
            v-if="!isViewCode"
            @keyup.enter="setAuth"
            @click:append="showPassword = !showPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :outlined="!flyairxpressHost"
            :dense="!flyairxpressHost"
            :prepend-inner-icon="flyairxpressHost ? 'mdi-key-variant' : ''"
          ).required-field.ma-2.mb-0.pb-0

          router-link(v-if="!isViewCode" :to="{ name: 'forgot-password' }").mb-4 {{ $t('forgot-password') }}

          +field-validation('body2FA.token', '', '["required"]')(
            v-if="isViewCode"
            :placeholder="$t('setCode')"
            autocomplete="one-time-code"
            dense
          )
      v-card-actions.ml-3.mr-3.mb-3.flex-column
        div
          v-btn(
            v-if="isViewCode"
            @click="isViewCode = false"
            color="secondary"
            text
          ).w-50 {{ $t('back') }}
          v-btn(
            @click="setAuth"
            :disabled="isViewCode ? this.$v.body2FA.$invalid : this.$v.body.$invalid"
            :class="{ 'w-full': !isViewCode, 'w-50': isViewCode }"
            :loading="isLoad"
            color="primary") {{ $t('sign') }}
</template>

<script>
import { authByCredits, authBy2FA } from '@/mixins/validationRules'
import LogoMRC from '@/assets/img/logoM.svg'
import Logo from '@/assets/img/logo-ITCS.svg'
import { mapState } from 'vuex'
import AuthGoogie from '../../components/atoms/AuthorizationGoogie'

export default {
  components: { AuthGoogie },
  data () {
    return {
      Logo,
      LogoMRC,
      showPassword: false,
      qr: null,
      body: {
        url: {
          login: 'api/v2/accounts/login/',
          u2f: 'api/v2/accounts/u2f/login/'
        },
        credits: {
          username: '',
          password: '',
          longitude: 0,
          latitude: 0
        }
      },
      body2FA: {
        url: 'api/v2/accounts/totp/login/',
        token: ''
      },
      isLoad: false,
      isViewCode: false,
      isViewFinger: false,
      isViewQR: false
    }
  },
  validations: {
    body: authByCredits,
    body2FA: authBy2FA
  },
  computed: {
    ...mapState({
      maradHost: state => state.main.maradHost,
      crewingHost: state => state.main.crewingHost,
      morrichserviceHost: state => state.main.morrichserviceHost,
      flyairxpressHost: state => state.main.flyairxpressHost
    })
  },
  watch: {
    isViewFinger (newValue) {
      // newValue && this.$swal(this.$t('putFingerOnKey'))
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'authorization', access: false })
  },
  mounted () {
    const { path } = this.$route.query
    this.$auth.isAuth() && window.location.replace(path || '/')
  },
  methods: {
    setAuth () {
      this.isLoad = true
      if (this.isViewCode) {
        this.$v.body2FA.$touch()
        !this.$v.body2FA.$invalid && this.authByTOTP()
      } else {
        this.$v.body.$touch()
        !this.$v.body.$invalid && this.checkGeolocation()
      }
    },

    submitGoogieForm (data) {
      this.isLoad = true
      if (this.isViewCode) {
        this.body2FA = data
        this.authByTOTP()
      } else {
        this.body = data
        this.checkGeolocation()
      }
    },

    goBackToLoginForm () {
      this.qr = null
      this.isViewCode = false
      this.isViewQR = false
    },

    async checkGeolocation () {
      if (process.env.VUE_APP_DEV_MODE !== 'true') { // Check user geolocation
        await this.$geo.getGeolocation()
        if (this.$geo.error) {
          this.isLoad = false
          return this.$notification.warning('notify.requiredGeolocation')
        } else {
          if (this.$geo.geolocation?.coords) this.body.credits = Object.keys(this.body.credits).reduce((t, k) => ({ ...t, [k]: this.$geo.geolocation.coords[k] }), {})
          this.authByCredits()
        }
      } else this.authByCredits()
    },

    authByCredits () {
      const { path } = this.$route.query

      this.$auth.byCredits(this.body.credits)
        .then(auth => {
          switch (auth.type) {
            case 'general':
              if (auth.status) {
                if (auth.requiredType) {
                  this.$swal({
                    title: this.$t('notify.title.warning'),
                    text: this.$t('authenticationWarning', { type: auth.requiredType, date: auth.requiredDate }),
                    icon: 'warning'
                  }).then(() => window.location.replace(path || '/'))
                } else window.location.replace(path || '/')
              }
              break
            case 'TOTP':
              this.isViewCode = auth.status || !!auth.data.qr
              this.isViewQR = auth.data && !!auth.data.qr
              this.qr = auth.data && auth.data.qr
              break
            case 'U2F':
              this.isViewFinger = auth.status
              this.$auth.byU2F().then(auth => {
                if (auth.status) {
                  if (!auth.requiredType) window.location.replace(path || '/')
                }
              })
              break
          }
        }).finally(() => {
          this.isLoad = false
        })
    },

    authByTOTP () {
      const { path } = this.$route.query

      this.$auth.byTOTP(this.body2FA)
        .then(auth => {
          switch (auth.type) {
            case 'verify':
              this.isViewQR = !auth.status
              this.body2FA.token = ''
              auth.status && window.location.replace(path || '/')
              break
            case 'auth':
              auth.status && window.location.replace(path || '/')
              break
          }
        }).catch(e => {
          // this.$notification.error(e.error)
        }).finally(() => {
          this.isLoad = false
        })
    }
  }
}
</script>

<style scoped>
  .required-field .v-label:after {
    content: '*';
    color: red;
    margin-left: 5px;
  }
</style>
